<template>
  <div class="qiqb-from">
		<a-result
			status="success"
			title="缓存清除成功"
			sub-title="当后台修改信息后,前端不生效时,可尝试清除缓存!"
		>
		</a-result>
  </div>
</template>

<script>
import { cache } from "../../../api/index.js";

export default {
  methods: {
    async more(e) {
      let res = await cache({ page: e.current });
      if (res.code == 200) {
				console.log(res)
      }
    }
  },
  mounted() {
    this.more({ current: 1 });
  },
  data() {
    return {
      name: "",
      form: this.$form.createForm(this),
      item: [],
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.name = route.name;
      },
      immediate: true,
    },
  },
};
</script>